import React, { Component } from "react"
import Layout from "../components/layout"
import "./index.scss"
import NavMainPC from "../components/navMainPC/NavMainPC"
import NavMainMobile from "../components/navMainMobile/NavMainMobile"
import NavMenuLeft from "../components/navMenuLeft/NavMenuLeft"
import About from "../components/about/About"
import Statistics from "../components/statistics/Statistics"
import Communities from "../components/communities/Communities"
import Timeline from "../components/timeline/Timeline"
import News from "../components/news/News"
import ClientsPartners from "../components/clientsPartners/ClientsPartners"
import Contact from "../components/contact/Contact"
import { withTrans } from "../i18n/withTrans"
import { Location } from '@reach/router'

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { location } = this.props;
    var info = location;
    if (info.host == "ookbeeu.com" || info.host == "www.ookbeeu.com" ) {
      window.location = "https://about.ookbee.com/";
    }
  }

  render() {
    const { t } = this.props
    return (
      <Layout>
        <div className="home layout d-none d-lg-block">
          <NavMainPC />
          <div className="row position">
            <NavMenuLeft />
            <div className="col-auto">

              <div className="logo">
                <img
                  className="imgCenter"
                  alt="icon"
                  src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/logo ookbee.png"
                />
              </div>
              <div className="logo2">
                <label className="header position" htmlFor="largest">
                  {t("home.header1")}
                </label>
                <label className="header2 position" htmlFor="ugc">
                  {t("home.header2")}
                </label>
                <label className="header3 position" htmlFor="ugc">
                  {t("home.header3")}
                </label>
              </div>

            </div>
          </div>
        </div>
        <div className="homeMobile layout d-block d-lg-none">
          <NavMainMobile />
          <div className="logo">
            <img
              className="imgCenter"
              alt="icon"
              src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/logo ookbee.png"
            />
            <p className="header">{t("home.header1")}</p>
            <p className="header">{t("home.header2")}</p>
            <p className="header">{t("home.header3")}</p>
          </div>
        </div>
        <div className="about">
          <section id="about" className="">
            <About />
          </section>
        </div>
        <div className="statistics">
          <section id="statistics" className="">
            <Statistics />
          </section>
        </div>
        <div className="communities">
          <section id="communities" className="">
            <Communities />
          </section>
        </div>
        <div className="timeline">
          <section id="timeline" className="">
            <Timeline />
          </section>
        </div>
        {/*<div className="news">
          <section id="news" className="">
            <News />
          </section>
    </div>*/}
        <div className="clients-partners">
          <section id="clients-partners" className="">
            <ClientsPartners />
          </section>
        </div>
        <div className="contact">
          <section id="contact" className="">
            <Contact />
          </section>
        </div>
      </Layout>
    )
  }
}

export default withTrans(IndexPage)