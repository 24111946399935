import React, { Component } from "react"
import "./CardImageText.scss"

class CardImageText extends Component {
  constructor(props) {
    super(props)
  }
  state = {}
  render() {
    const { title, imageUrl, description } = this.props.items[0]
    return (
      <div className="row cardImageText">
        <div className="col-5 boxCenter">
          <img className="imgCenter" src={imageUrl} alt={title} />
        </div>
        <div className="col-7 boxCenter">
          <h4 className="text">{description}</h4>
        </div>
      </div>
    )
  }
}

export default CardImageText
