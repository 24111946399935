import React, { Component } from "react"
import "./CardText.scss"

class CardText extends Component {
  constructor(props) {
    super(props)
  }
  state = {}
  render() {
    const { description } = this.props.items[0]
    return (
      <div className="cardText">
        <div className="container">
          <h4 className="text">{description}</h4>
        </div>
      </div>
    )
  }
}

export default CardText
