import React, { Component } from "react"
import "./News.scss"
import { withTrans } from "../../i18n/withTrans"

class NewsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    const { t } = this.props;
    return ( <div className="pageNews">
    <h1>{t("news.title")}</h1>
    <div className="bg">
      <div className="scroll">
        <span>What’s in a name? For text logos, the answer is simple: everything. Also
        referred to as wordmark logos, text logos keep things minimal—but that
        doesn’t mean they have to be boring. These logos contain only a company
        name in a distinctive font, and they’re ideal for short business names,
        or if you plan to use your logo across several mediums. Because of their
        simplicity, text logos require a careful eye for detail. Sometimes, a
        small change to your font, color, or character feature is all it takes
        to transform your logo from forgettable to iconic. To create a wordmark
        that makes a mark, you first need to learn the ABCs of typography and
        design. Explore our logo inspiration collection and read through our
        design tips for this logo style. When you’re ready to take the next
        step, create your own logo using ou What’s in a name? For text logos,
        the answer is simple: everything. Also referred to as wordmark logos,
        text logos keep things minimal—but that doesn’t mean they have to be
        boring. These logos contain only a company name in a distinctive font,
        and they’re ideal for short business names, or if you plan to use your
        logo across several mediums. Because of their simplicity, text logos
        require a careful eye for detail. Sometimes, a small change to your
        font, color, or character feature is all it takes to transform your logo
        from forgettable to iconic. To create a wordmark that makes a mark, you
        first need to learn the ABCs of typography and design. Explore our logo
        inspiration collection and read through our design tips for this logo
        style. When you’re ready to take the next step, create your own logo
        using ou What’s in a name? For text logos, the answer is simple:
        everything. Also referred to as wordmark logos, text logos keep things
        minimal—but that doesn’t mean they have to be boring. These logos
        contain only a company name in a distinctive font, and they’re ideal for
        short business names, or if you plan to use your logo across several
        mediums. Because of their simplicity, text logos require a careful eye
        for detail. Sometimes, a small change to your font, color, or character
        feature is all it takes to transform your logo from forgettable to
        iconic. To create a wordmark that makes a mark, you first need to learn
        the ABCs of typography and design. Explore our logo inspiration
        collection and read through our design tips for this logo style. When
        you’re ready to take the next step, create your own logo using ou What’s
        in a name? For text logos, the answer is simple: everything. Also
        referred to as wordmark logos, text logos keep things minimal—but that
        doesn’t mean they have to be boring. These logos contain only a company
        name in a distinctive font, and they’re ideal for short business names,
        or if you plan to use your logo across several mediums. Because of their
        simplicity, text logos require a careful eye for detail. Sometimes, a
        small change to your font, color, or character feature is all it takes
        to transform your logo from forgettable to iconic. To create a wordmark
        that makes a mark, you first need to learn the ABCs of typography and
        design. Explore our logo inspiration collection and read through our
        design tips for this logo style. When you’re ready to take the next
        step, create your own logo using ou What’s in a name? For text logos,
        the answer is simple: everything. Also referred to as wordmark logos,
        text logos keep things minimal—but that doesn’t mean they have to be
        boring. These logos contain only a company name in a distinctive font,
        and they’re ideal for short business names, or if you plan to use your
        logo across several mediums. Because of their simplicity, text logos
        require a careful eye for detail. Sometimes, a small change to your
        font, color, or character feature is all it takes to transform your logo
        from forgettable to iconic. To create a wordmark that makes a mark, you
        first need to learn the ABCs of typography and design. Explore our logo
        inspiration collection and read through our design tips for this logo
        style. When you’re ready to take the next step, create your own logo
        using ou What’s in a name? For text logos, the answer is simple:
        everything. Also referred to as wordmark logos, text logos keep things
        minimal—but that doesn’t mean they have to be boring. These logos
        contain only a company name in a distinctive font, and they’re ideal for
        short business names, or if you plan to use your logo across several
        mediums. Because of their simplicity, text logos require a careful eye
        for detail. Sometimes, a small change to your font, color, or character
        feature is all it takes to transform your logo from forgettable to
        iconic. To create a wordmark that makes a mark, you first need to learn
        the ABCs of typography and design. Explore our logo inspiration
        collection and read through our design tips for this logo style. When
        you’re ready to take the next step, create your own logo using ou What’s
        in a name? For text logos, the answer is simple: everything. Also
        referred to as wordmark logos, text logos keep things minimal—but that
        doesn’t mean they have to be boring. These logos contain only a company
        name in a distinctive font, and they’re ideal for short business names,
        or if you plan to use your logo across several mediums. Because of their
        simplicity, text logos require a careful eye for detail. Sometimes, a
        small change to your font, color, or character feature is all it takes
        to transform your logo from forgettable to iconic. To create a wordmark
        that makes a mark, you first need to learn the ABCs of typography and
        design. Explore our logo inspiration collection and read through our
        design tips for this logo style. When you’re ready to take the next
        step, create your own logo using ou What’s in a name? For text logos,
        the answer is simple: everything. Also referred to as wordmark logos,
        text logos keep things minimal—but that doesn’t mean they have to be
        boring. These logos contain only a company name in a distinctive font,
        and they’re ideal for short business names, or if you plan to use your
        logo across several mediums. Because of their simplicity, text logos
        require a careful eye for detail. Sometimes, a small change to your
        font, color, or character feature is all it takes to transform your logo
        from forgettable to iconic. To create a wordmark that makes a mark, you
        first need to learn the ABCs of typography and design. Explore our logo
        inspiration collection and read through our design tips for this logo
        style. When you’re ready to take the next step, create your own logo
        using ou</span>
      </div>
    </div>
  </div> );
  }
}
 
export default withTrans(NewsPage)