import React, { Component } from "react"
import "./CardRight.scss"

class CardImages extends Component {
  constructor(props) {
    super(props)
  }
  state = {}
  render() {
    const { title, imageUrl, description, side } = this.props.items[0]
    var className = "main " +  side;
    return (
      <div className={className}>
        <div className="row">
          <div className="col-4 boxCenter">
            <img className="imgCenter" src={imageUrl} alt={title} />
          </div>
          <div className="col-8 boxCenter">
            <h4 className="text">{description}</h4>
          </div>
        </div>
      </div>
    )
  }
}

export default CardImages
