import React, { Component } from "react"
import "./Card.scss"
import CardItem from "../cardItem/CardItem"
import CardImage from "../cardImage/CardImage"
import CardText from "../cardText/CardText"
import CardImageText from "../cardImageText/CardImageText"
import CardRight from "../cardRight/CardRight"
import CardLeft from "../cardLeft/CardLeft"
import { withTrans } from "../../i18n/withTrans"

class Card extends Component {
  constructor(props) {
    super(props)
  }
  state = {}
  render() {
    const { t } = this.props
    const widgetItems = {
      items: [
        {
          id: 1,
          title: "description1",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/place.png",
          description: t("timeline.box1"),
          side: "right",
        },
        {
          id: 2,
          title: "description2",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/map.png",
          description: t("timeline.box2"),
          side: "left",
        },
        {
          id: 3,
          title: "description3",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/eBook.png",
          description: t("timeline.box3"),
          side: "right",
        },
        {
          id: 4,
          title: "description4",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/social.png",
          description: t("timeline.box4"),
          side: "left",
        },
        {
          id: 5,
          title: "description5",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/logo.png",
          description: t("timeline.box5"),
          side: "right",
        },
      ],
    }
    let items1 = widgetItems.items.filter(function (item) {
      return item.id === 1
    })
    let items2 = widgetItems.items.filter(function (item) {
      return item.id === 2
    })
    let items3 = widgetItems.items.filter(function (item) {
      return item.id === 3
    })
    let items4 = widgetItems.items.filter(function (item) {
      return item.id === 4
    })
    let items5 = widgetItems.items.filter(function (item) {
      return item.id === 5
    })
    return (
      <div>
        <div className="d-none d-lg-block">
          <div className="container">
            <div className="left-half">
              <article>
                <CardRight items={items1} />
              </article>
            </div>
            <div className="right-half">
              <article></article>
            </div>

            <div className="left-half">
              <article></article>
            </div>
            <div className="right-half">
              <article>
                <CardLeft items={items2} />
              </article>
            </div>

            <div className="left-half">
              <article>
                <CardRight items={items3} />
              </article>
            </div>
            <div className="right-half">
              <article></article>
            </div>

            <div className="left-half">
              <article></article>
            </div>
            <div className="right-half">
              <article>
                <CardLeft items={items4} />
              </article>
            </div>

            <div className="left-half">
              <article>
                <CardRight items={items5} />
              </article>
            </div>
            <div className="right-half">
              <article></article>
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none">
          <div className="container borderLeft">
            <div className="topCard">
              <CardLeft items={items1} />
            </div>
            <div className="topCard">
              <CardLeft items={items2} />
            </div>
            <div className="topCard">
              <CardLeft items={items3} />
            </div>
            <div className="topCard">
              <CardLeft items={items4} />
            </div>
            <div className="topCard">
              <CardLeft items={items5} />
            </div>
          </div>
        </div>
        {/*<div className="d-none d-lg-block">
          <div className="container">
            <div className="left-half">
              <article>
                <CardImageText items={items1} />
              </article>
            </div>
            <div className="right-half">
              <article></article>
            </div>
            <div className="left-half">
              <article></article>
            </div>
            <div className="right-half">
              <article>
                <CardImageText items={items2} />
              </article>
            </div>
            <div className="left-half">
              <article>
                <CardImageText items={items3} />
              </article>
            </div>
            <div className="right-half">
              <article></article>
            </div>
            <div className="left-half">
              <article></article>
            </div>
            <div className="right-half">
              <article>
                <CardImageText items={items4} />
              </article>
            </div>
            <div className="left-half">
              <article>
                <CardImageText items={items5} />
              </article>
            </div>
            <div className="right-half">
              <article></article>
            </div>
          </div>
    </div>*/}
      </div>
    )
  }
}

export default withTrans(Card)
