import React, { Component } from "react"
import "./Statistics.scss"
import { withTrans } from "../../i18n/withTrans"

class StatisticsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { t } = this.props
    return (
      <div className="pageStatistics">
        <div className="row statistics">
          <h1 className="header">
            <span>{t("statistics.title")}</span>
          </h1>
        </div>
        <div className="row paddingTiltle">
          <div className="col-6">
          <p className="titleBig">#NO.1</p>
          </div>
          <div className="col-6">
          <p className="title">{t("statistics.activeUser")}</p>
          <p className="title">{t("statistics.activeUser2")}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <img
              className="imgActiveUser"
              src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/activeUser v.4.png"
            />
          </div>
          {/*<div className="col-4">
            <img
              className="imgPlatform"
              src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformOokbee.png"
            />
          /div>*/}
        </div>
        {/*<div className="row">
          <p className="comment">
            As of October 2020, OOKBEE group applications has more monthly
            active users than Twitter.
          </p>
        </div>*/}
      </div>
    )
  }
}

export default withTrans(StatisticsPage)
