import React, { Component } from "react"
import "./About.scss"
import { withTrans } from "../../i18n/withTrans"

class AboutPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { t } = this.props
    return (
      <div className="pageAbout">
        <div className="row">
          <div className="d-none d-lg-block col-auto vl"></div>
          <div className="col-auto">
            <p className="title">{t("about.intro")}</p>
            <p className="subTitle">{t("about.description")}</p>
          </div>
        </div>
        <div className="row imgTop">
          <img
            className="imgCenter"
            alt="icon"
            src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/ipad_new.png"
          />
        </div>
        <div className="row ceo">
          <img
            className="imgCeo"
            alt="icon"
            src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/ceoHead.png"
          />
        </div>
        <div className="block">
          <div className="background">
            <p className="head italic"> {t("about.sayCeo1")}</p>
            <p className="head italic">{t("about.sayCeo2")}</p>
          </div>
        </div>
        <div className="row blockButton">
          <div className="col-12">
            <button className="btnPartner">{t("about.nameCeo")}</button>
          </div>
        </div>
        <div className="row investors">
          <p className="title">{t("about.investors")}</p>
          <div className="row imgLogo">
            <div className="col-4">
              <img
                className="logo"
                alt="icon"
                src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/tencent.png"
              />
            </div>
            <div className="col-4">
              <img
                className="logo"
                alt="icon"
                src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/transcosmos.png"
              />
            </div>
            <div className="col-4">
              <img
                className="logo"
                alt="icon"
                src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/intouch.png"
              />
            </div>
          </div>
        </div>
        <div className="block descriptionMember">
          <div className="background2">
            <p className="head description">{t("about.descriptionInvestors")}</p>
          </div>
        </div>
        <div className="d-none d-lg-block row ookbeeGroup">
          <img
            className="imgCenter"
            alt="icon"
            src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/ookbeeGroupv.3.png"
          />
        </div>
        <div className="d-block d-lg-none row ookbeeGroup">
          <img
            className="imgCenter"
            alt="icon"
            src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/ookbeeGroupMbv.3.png"
          />
        </div>
      </div>
    )
  }
}

export default withTrans(AboutPage)
