import React, { Component } from "react"
import "./NavMainMobile.scss"
import LanguageMenu from "../languageMenu/LanguageMenu"
import { withTrans } from "../../i18n/withTrans"

class NavMainMobile extends Component {
  menuItems = {
    items: [
      {
        displayName: "ABOUT",
        linkUrl: "#about",
      },
      {
        displayName: "STATISTICS",
        linkUrl: "#statistics",
      },
      {
        displayName: "COMMUNITIES",
        linkUrl: "#communities",
      },
      {
        displayName: "TIMELINE",
        linkUrl: "#timeline",
      },
      {
        displayName: "NEWS",
        linkUrl: "#news",
      },
      {
        displayName: "CLIENTS & PARTNERS",
        linkUrl: "#clients-partners",
      },
      {
        displayName: "CONTACT",
        linkUrl: "#contact",
      },
    ],
  }
  state = {
    isOpenMenuList: false,
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false)
  }

  toggleMenuList = () => {
    const { isOpenMenuList } = this.state
    if (!isOpenMenuList) {
      document.addEventListener("click", this.handleOutsideClick, false)
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false)
    }
    this.setState(prevState => ({
      isOpenMenuList: !prevState.isOpenMenuList,
    }))
  }

  handleOutsideClick = event => {
    if (event.target.className.includes("menuListContainer ")) {
      this.toggleMenuList()
    }
  }

  renderMenuList = ({}) => {
    const { isOpenMenuList } = this.state;
    const { t } = this.props;
    return (
      <div
        className={`menuListContainer ${
          isOpenMenuList ? "openMenu" : "closeMenu"
        }`}
      >
        <div
          className={`menuList ${isOpenMenuList ? "openMenu" : "closeMenu"}`}
        >
          <div className="d-flex justify-content-end">
            <i
              className="fas fa-times closeMenuList"
              onClick={this.toggleMenuList}
            ></i>
          </div>

          <div className="menuListHeader">
            <b>{t('menu.title')}</b>
          </div>
          {this.menuItems.items.map((menu, index) => {
            const { displayName, linkUrl } = menu
            return (
              <div className="menuListItem" key={`menuListItem-${index}`}>
                <a href={linkUrl}>
                  {displayName == "ABOUT" && t('about.title') }
                  {displayName == "STATISTICS" && t('statistics.title') }
                  {displayName == "COMMUNITIES" && t('communities.title') }
                  {displayName == "TIMELINE" && t('timeline.title') }
                  {/*displayName == "NEWS" && t('news.title') */}
                  {displayName == "CLIENTS & PARTNERS" && t('clientsPartners.title') }
                  {displayName == "CONTACT" && t('contact.title') }
                </a>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="navMainMobile">
        <div className="container">
          <div className="row justify-content-between align-items-end">
            <div
              className="col-auto navMainMobileItem"
              onClick={this.toggleMenuList}
            >
              <a className="link">
                <img
                  className="icon"
                  src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/burgerMenu.png"
                />
              </a>
            </div>
            <div className="col-auto navMainMobileItem">
              <a href={"/"} className="ookbeeLogo">
                <img
                  className="image"
                  src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/ookbee.png"
                />
              </a>
            </div>
            <div className="col-auto justify-content-end navMainMobileItem">
              <LanguageMenu size="mobile" />
              {/*<a className="link">
                <img
                  className="icon"
                  src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/language.png"
                />
              </a>*/}
            </div>
          </div>
        </div>
        {this.renderMenuList({})}
      </div>
    )
  }
}
export default withTrans(NavMainMobile)