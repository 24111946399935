import _ from 'lodash'
import React, { Component } from 'react'
import ReadMoreReact from 'read-more-react';
//import MultiClamp from 'react-multi-clamp'
//import LinesEllipsis from "react-lines-ellipsis";
class ClampText extends Component {
    render() {
        const { text, lines } = this.props
        return (
            <ReadMoreReact text={text}
            min={50}
            ideal={100}
            max={200}
            readMoreText="read more"/>
        )
    }
}

export default ClampText

// <LinesEllipsis
// text={text}
// basedOn="letters"
// maxLine={lines || 1}
// ellipsis={<span style={{ color: "red" }}>...read more</span>}
// style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
// />
        
// // <MultiClamp clamp={lines || 1} ellipsis='...'>{text}</MultiClamp>