import React, { Component } from "react"
import "./BlockItem.scss"
import Slider from "react-slick"
import { withTrans } from "../../i18n/withTrans"
import ClampText from "../clampText/ClampText";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const NextButton = props => {
  const { className, onClick } = props
  return (
    <div
      className={`carouselButton rigth ${className}`}
      onClick={onClick}
    ></div>
  )
}

const PrevButton = props => {
  const { className, onClick } = props
  return (
    <div className={`carouselButton left ${className}`} onClick={onClick}></div>
  )
}

class BlockItem extends Component {
  state = {
    defaultSettings: {
      dots: false,
      lazyLoad: true,
      swipeToSlide: true,
      nextArrow: <NextButton />,
      prevArrow: <PrevButton />,
    },
  }

  renderItems = (widgetItems, classCol = "") => {
    return widgetItems.map((widget, index) => {
      const { title, imageUrl, description } = widget
      return (
        <div
          className={`${classCol} categoryListItem`}
          key={`categoryListItem-${index}-${title}`}
          title={title}
          // onDragStart={this.handleOnDrag}
          // onDragEnd={this.handleOnDrag}
        >
          <div className="categoryItemLink">
            <div className="cover">
              <div className="categoryItemCover">
                <img
                  className="catagoryCoverSize"
                  alt="icon"
                  src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/transparent_3x4.png"
                />
                <img
                  className="categoryImage"
                  src={
                    imageUrl ||
                    "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/category.png"
                  }
                  alt={`${title}`}
                />
              </div>
              <div className="contentItemBody">
                {description && (
                  <div className="contentDescription"><ClampText lines={5} text={description} /></div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    })
  }
  render() {
    //const { defaultSettings } = this.state
    var slideSettings = {
      centerMode: true,
      slidesToShow: 5,
      dots: true,
      arrows: true,
      swipe: true,
      swipeToSlide: true,
      speed: 500,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
            centerMode: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            centerMode: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
      ],
    }
    // var slideSettings = {
    //   dots: true,
    //   infinite: false,
    //   speed: 500,
    //   slidesToShow: 5,
    //   slidesToScroll: 4,
    //   initialSlide: 0,
    //   //arrows: false,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 3,
    //         infinite: true,
    //         dots: true,
    //       },
    //     },
    //     {
    //       breakpoint: 600,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2,
    //         initialSlide: 2,
    //       },
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2,
    //       },
    //     },
    //   ],
    // }
    //const settings = { ...defaultSettings, ...slideSettings };
    const settings = { ...slideSettings };
    const { t } = this.props;
    const widgetItems = {
      items: [
        {
          id: 1,
          title: "description1",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/place.png",
          description: t("timeline.box1"),
        },
        {
          id: 2,
          title: "description2",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/map.png",
          description: t("timeline.box2")
        },
        {
          id: 3,
          title: "description3",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/eBook.png",
          description: t("timeline.box3")
        },
        {
          id: 4,
          title: "description4",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/social.png",
          description: t("timeline.box4")
        },
        {
          id: 5,
          title: "description5",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/logo.png",
          description: t("timeline.box5")
        },
        {
          id: 6,
          title: "description6",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/logo.png",
          description: t("timeline.box5")
        },
        {
          id: 7,
          title: "description7",
          imageUrl:
            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/timeline/logo.png",
          description: t("timeline.box5")
        }
      ],
    };
    return (
      <Slider {...settings} className="categoryListBook slick-slider">
        {this.renderItems(widgetItems.items)}
      </Slider>
    )
  }
}
export default withTrans(BlockItem)