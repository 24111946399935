import React, { Component } from "react"
import Card from "../cardItem/CardItem"
import "./CardItem.scss"

class CardItem extends Component {
  constructor(props) {
    super(props)
  }
  state = {}
  render() {
    const { title, imageUrl, description } = this.props.items[0]
    return (
      <div className="cardItem">
        <img className="imgCenter" src={imageUrl} alt={title} />
        <div className="container">
          <h4 className="text">
            {description}
          </h4>
        </div>
      </div>
    )
  }
}

export default CardItem
