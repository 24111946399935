import React, { Component } from "react"
import "./CardImage.scss"

class CardImage extends Component {
  constructor(props) {
    super(props)
  }
  state = {}
  render() {
    const { title, imageUrl } = this.props.items[0]
    return (
      <div className="cardImage">
        <img className="imgCenter" src={imageUrl} alt={title} />
      </div>
    )
  }
}

export default CardImage
