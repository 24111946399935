import React, { Component } from "react"
//import ContentItem from "../contentItem/ContentItem"
//import BoxItem from "../boxItem/BoxItem"
import BlockItem from "../blockItem/BlockItem"
import Card from "../card/Card"
import "./Timeline.scss"

class TimelinePage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="pageTimeline">
        <div className="row timeline">
          <h1 className="header">
            <b>TIMELINE</b>
          </h1>
        </div>
        <div className="row">
          {/*<BlockItem />*/}
          <Card />
        </div>
      </div>
    )
  }
}

export default TimelinePage
