import React, { Component } from "react"
import "./ClientsPartners.scss"
import { withTrans } from "../../i18n/withTrans"

class ClientsPartnersPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { t } = this.props;
    return (
      <div className="pageClientsPartners">
        <h1>{t("clientsPartners.title")}</h1>
        <div className="row">
          <div className="col-auto">
            <img
              className="imgCenter"
              src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/Product v.3.png"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withTrans(ClientsPartnersPage)
