import React, { Component } from "react"
import "./Contact.scss"
import { withTrans } from "../../i18n/withTrans"

class ContactPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { t } = this.props
    return (
      <div className="pageContact">
        <div className="inside">
          <h1>{t("contact.title3")}</h1>
          <div className="row partner">
            <div className="col-8 center">
              <p className="head">{t("contact.forPartner")}</p>
              <div className="row">
                <div className="col-6">
                  <a className="btnPartner" href="mailto:Marketing@ookbee.com">
                    {t("contact.businessPartner")}
                  </a>
                </div>
                <div className="col-6">
                  <a className="btnPartner" href="mailto:procurement@ookbee.com">
                    {t("contact.contentPartner")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-4 center">
              <p className="head">{t("contact.forinvestor")}</p>
              <div className="col-12 center">
               <a className="btnPartner" href="mailto:bdteam@ookbee.com">
                {t("contact.title2")}
               </a>
              </div>
            </div>
          </div>
          <div className="row customer">
            <p className="head">{t("contact.forCustomer")}</p>
            <p className="description">member@ookbee.com</p>
            <p className="description">{t("contact.forCustomerContact")}</p>

            <p className="head" style={{ paddingTop: "1rem" }}>
              {t("contact.forCompany")}
            </p>
            <p className="description">{t("contact.forCompanyContact")}</p>
          </div>
          <div className="row joinTeam">
            <div className="col-12 center">
              <a
                href="https://hiring.ookbee.com/"
                target="_blank"
                className="btnJoinTeam"
              >
                {t("contact.joinTeam")}
              </a>
            </div>
          </div>
          <div className="row address">
            <p className="description">
              <span className="head">{t("contact.address")} : </span>
              &nbsp;&nbsp;&nbsp;{t("contact.addressDescription")}
            </p>
          </div>
        </div>
        <div className="row map">
          <iframe
            width="100%"
            height="800px"
            frameBorder="0"
            scrolling="no"
            frameBorder="0"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=13.7361809,100.61547029999997&amp;q=Ookbee%20U%20(Thailand)%20Co.%2CLtd.%2C%20Phatthanakan%20Road%2C%20Suan%20Luang%2C%20Bangkok%2C%20Thailand+(Ookbee)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
          />
        </div>
      </div>
    )
  }
}
export default withTrans(ContactPage)
