import React, { Component } from "react"
import "./NavMenuLeft.scss"
import { withTrans } from "../../i18n/withTrans"
//'../i18n/withTrans'

class NavMenuLeft extends Component {
  menuItems = {
    items: [
      {
        displayName: "ABOUT",
        linkUrl: "#about",
      },
      {
        displayName: "STATISTICS",
        linkUrl: "#statistics",
      },
      {
        displayName: "COMMUNITIES",
        linkUrl: "#communities",
      },
      {
        displayName: "TIMELINE",
        linkUrl: "#timeline",
      },
      /*{
        displayName: "NEWS",
        linkUrl: "#news",
      },*/
      {
        displayName: "CLIENTS & PARTNERS",
        linkUrl: "#clients-partners",
      },
      {
        displayName: "CONTACT",
        linkUrl: "#contact",
      },
    ],
  }

  renderMenuList = () => {
    const { t } = this.props;
    return (
      <div className="space">
        {this.menuItems.items.map((menu, index) => {
          const { displayName, linkUrl } = menu
          return (
            <div className="row titleSection" key={`menuListItem-${index}`}>
              <div id="bb" className="circle col-auto"></div>
              <div className="col-auto">
                {/*<img
                className=""
                alt="icon"
                src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/oval.png"
              />*/}{" "}
                <a id="aa" href={linkUrl} className="textMenu">

                  {displayName == "ABOUT" && t('about.title') }
                  {displayName == "STATISTICS" && t('statistics.title') }
                  {displayName == "COMMUNITIES" && t('communities.title') }
                  {displayName == "TIMELINE" && t('timeline.title') }
                  {/*displayName == "NEWS" && t('news.title') */}
                  {displayName == "CLIENTS & PARTNERS" && t('clientsPartners.title') }
                  {displayName == "CONTACT" && t('contact.title') }
                </a>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    return <div className="col-auto menu">{this.renderMenuList({})}</div>
  }
}

export default withTrans(NavMenuLeft)
