import React, { Component } from "react"
import "./Communities.scss"
import { withTrans } from "../../i18n/withTrans"

class CommunitiesPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { t } = this.props
    var language = t.language
    return (
      <div className="pageCommunities">
        <div className="header">{t("communities.title")}</div>
        <div className="platformsUgc">
          <div id="circle-wrapper" className="d-none d-lg-block">
            <div className="circle1 transparent_class">
              <div className="wrapper"></div>
            </div>
            <div className="circle2 transparent_class">
              <div className="wrapper">
                <p className="notable">{t("communities.notable")}</p>
              </div>
            </div>
            <div className="row">
              <div className="ookbee">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/icon-ookbee.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="head">DIGITAL PUBLICATION</p>
                        <p className="subHead">OOKBEE</p>
                        <p className="subDescription">
                          {t("communities.ookbeeCommunity")}
                        </p>
                      </div>
                    </div>
                    <div className="row description">
                      <div className="col-auto">
                        <p>{t("communities.ookbeeDescription")}</p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/view.png"
                        />
                        <p className="titleIcon reIcon">100 millions</p>
                        <p className="descriptionIcon">
                          Screen/Pageview per month
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/new_user.png"
                        />
                        <p className="titleIcon reIcon2">3M+</p>
                        <p className="descriptionIcon">MAU</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/calendar.png"
                        />
                        <p className="titleIcon reIcon3">User Spend</p>
                        <p className="descriptionIcon">60 Mins/Day</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                      dangerouslySetInnerHTML={{
                        __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="90%"
                      height="90%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/279x569 ookbeer2.mp4"
                    />,
                  `,
                      }}
                    ></div>
                  </div>
                  {/*<div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                    >
                      <video
                        width="90%"
                        height="90%"
                        preload="auto"
                        style={{borderRadius:"2em"}}
                        loop
                        autoPlay
                        muted
                        controls
                        playsinline
                      >
                        <source
                          src={
                            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/279x569 ookbeer2.mp4"
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                        </div>*/}
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://www.ookbee.com/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>
                    <a href="https://www.facebook.com/Ookbee/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>
                    <a href="https://twitter.com/OokbeeApp" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/th/app/ookbee-ร-านหน-งส-อออนไลน/id667829467?l=th"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ookbee.ookbeeapp&hl=th"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    {/*<span className="padding">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                      />
                        </span>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="joy">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/icon.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="head">Thailand’s First</p>
                        <p className="subHead">Chat Style Novel Platform</p>
                        <p className="subDescription">
                          {t("communities.joyCommunity")}
                        </p>
                      </div>
                    </div>
                    <div className="row description">
                      <div className="col-auto">
                        <p>{t("communities.joyDescription")}</p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/touch.png"
                        />
                        <p className="titleIcon reIcon">2 Billions Joys</p>
                        <p className="descriptionIcon">Screen Touch per Day</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/new_user.png"
                        />
                        <p className="titleIcon reIcon2">3M+</p>
                        <p className="descriptionIcon">MAU</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/calendar.png"
                        />
                        <p className="titleIcon reIcon3">Users Spend</p>
                        <p className="descriptionIcon">90 Mins/Day</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                      dangerouslySetInnerHTML={{
                        __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="85%"
                      height="85%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/Joylada 530x1080.mp4"
                    />,
                  `,
                      }}
                    ></div>
                  </div>
                  {/*<div className="col-4 right">
                    <div className="screen">
                      <video
                        width="85%"
                        height="85%"
                        preload="auto"
                        style={{borderRadius:"2em"}}
                        loop
                        autoPlay
                        muted
                        controls
                      >
                        <source
                          src={
                            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/Joylada 530x1080.mp4"
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                        </div>*/}
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://www.joylada.com/home" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>

                    <a
                      href="https://www.facebook.com/webjoylada/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>

                    <a href="https://twitter.com/webjoylada" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/us/app/id1250405456?ls=1"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ookbee.joyapp.android"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://appgallery.huawei.com/#/app/C101105549?accessId=fadb0b708bce428fbfdc15f7c9f81d81"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="tunwalai">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/icon v.4.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="head">Leading Social Platform For</p>
                        <p className="subHead">UGC Novel Books</p>
                        <p className="subDescription">
                          {t("communities.tunwalaiCommunity")}
                        </p>
                      </div>
                    </div>
                    <div className="row description">
                      <div className="col-auto">
                        <p>{t("communities.tunwalaiDescription")}</p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/view.png"
                        />
                        <p className="titleIcon reIcon">300 millions</p>
                        <p className="descriptionIcon">
                          Screen/pageview per month
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/new_user.png"
                        />
                        <p className="titleIcon reIcon2">3M+</p>
                        <p className="descriptionIcon">MAU</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/calendar.png"
                        />
                        <p className="titleIcon reIcon3">Users Spend</p>
                        <p className="descriptionIcon">60 Mins/Day</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                      dangerouslySetInnerHTML={{
                        __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="85%"
                      height="85%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/279x569 ธัญวลัยr2.mp4"
                    />,
                  `,
                      }}
                    ></div>
                  </div>
                  {/*<div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                    >
                      <video
                        width="92%"
                        height="92%"
                        preload="auto"
                        style={{borderRadius:"2em"}}
                        loop
                        autoPlay
                        muted
                        controls
                      >
                        <source
                          src={
                            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/279x569 ธัญวลัยr2.mp4"
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                        </div>*/}
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="http://www.tunwalai.com/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/webtunwalai/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>
                    <a href="https://twitter.com/webtunwalai" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/th/app/ธ-ญวล-ย-น-ยายออนไลน/id1087664350"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ookbee.tunwalai.android"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ookbee.tunwalai.android"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="adung">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/icon.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="head">Horoscope social</p>
                        <p className="subHead">
                          Online Horoscope Social Platform
                        </p>
                        <p className="subDescription">
                          {t("communities.aDungCommunity")}
                        </p>
                      </div>
                    </div>
                    <div className="row description">
                      <div className="col-auto">
                        <p>{t("communities.aDungDescription")}</p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/view.png"
                        />
                        <p className="titleIcon reIcon">800,000+</p>
                        <p className="descriptionIcon">
                          Followers on Social Media
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/new_user.png"
                        />
                        <p className="titleIcon reIcon2">2M+</p>
                        <p className="descriptionIcon">
                          MAU (Application and Website combined)
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/calendar.png"
                        />
                        <p className="titleIcon reIcon3">3,700</p>
                        <p className="descriptionIcon">Fortune tellers</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                      dangerouslySetInnerHTML={{
                        __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="85%"
                      height="85%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/279x569 aดวงr2.mp4"
                    />,
                  `,
                      }}
                    ></div>
                  </div>
                  {/*<div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                    >
                      <video
                        width="90%"
                        height="90%"
                        preload="auto"
                        style={{borderRadius:"2em"}}
                        loop
                        autoPlay
                        muted
                        controls
                      >
                        <source
                          src={
                            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/279x569 aดวงr2.mp4"
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                        </div>*/}
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://aduang.co/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>

                    <a
                      href="https://www.facebook.com/aduang.co/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>

                    <a href="https://twitter.com/aduangTH" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/app/apple-store/id1227408979?pt=1560061"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ookbee.aduang"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    {/*<span className="padding">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                      />
                        </span>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="beeber">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/icon.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="head">Beeber</p>
                        <p className="subHead">
                          Beeber - Video and Audio LiveStreaming
                        </p>
                        <p className="subDescription">
                          {t("communities.beeberCommunity")}
                        </p>
                      </div>
                    </div>
                    <div className="row description">
                      <div className="col-auto">
                        <p>{t("communities.beeberDescription")}</p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/download.png"
                        />
                        <p className="titleIcon reIcon">500K+</p>
                        <p className="descriptionIcon">App Downloads</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/dj.png"
                        />
                        <p className="titleIcon reIcon2">20K+</p>
                        <p className="descriptionIcon">DJs</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/microphone.png"
                        />
                        <p className="titleIcon reIcon3"> 350k+</p>
                        <p className="descriptionIcon">Live Rooms</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                      dangerouslySetInnerHTML={{
                        __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="85%"
                      height="85%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/Beeber 530x1080.mp4"
                    />,
                  `,
                      }}
                    ></div>
                  </div>
                  {/*<div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                    >
                      <video
                        width="90%"
                        height="90%"
                        preload="auto"
                        style={{borderRadius:"2em"}}
                        loop
                        autoPlay
                        muted
                        controls
                      >
                        <source
                          src={
                            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/Beeber 530x1080.mp4"
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                        </div>*/}
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://beeber.live/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/BeeBer.live/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>
                    <a href="https://twitter.com/beeberlive" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/th/app/beeber-live/id1492678665"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>

                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=ookbee.beeber.live"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>

                    {/*<span className="padding">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                      />
                        </span>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="fungjai">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/icon.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="head">
                          Fungjai – Leading Music Streaming
                        </p>
                        <p className="subHead">& Live Event Ecosytem</p>
                        <p className="subDescription">
                          {t("communities.fungjaiCommunity")}
                        </p>
                      </div>
                    </div>
                    <div className="row description">
                      <div className="col-auto">
                        <p>{t("communities.fungjaiDescription")} </p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/follower.png"
                        />
                        <p className="titleIcon reIcon">420,000+</p>
                        <p className="descriptionIcon">Followers on Facebook</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/new_song.png"
                        />
                        <p className="titleIcon reIcon2">2M</p>
                        <p className="descriptionIcon">
                          Song streamed per month
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/new_user.png"
                        />
                        <p className="titleIcon reIcon3">4.6K</p>
                        <p className="descriptionIcon">Artists</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div
                      className="screen"
                      style={{ marginRight: "0.1rem", paddingRight: "1rem" }}
                      dangerouslySetInnerHTML={{
                        __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="85%"
                      height="85%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/Fungjai 530x1080.mp4"
                    />,
                  `,
                      }}
                    ></div>
                  </div>
                  {/*<div className="col-4 right">
                    <div className="screen">
                      <video
                        width="85%"
                        height="85%"
                        preload="auto"
                        style={{borderRadius:"2em"}}
                        loop
                        autoPlay
                        muted
                        controls
                      >
                        <source
                          src={
                            "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/Fungjai 530x1080.mp4"
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                        </div>*/}
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://www.fungjai.com/home" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/hellofungjai/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>
                    <a href="https://twitter.com/hellofungjai" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/th/app/fungjai/id999518766"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.fungjai"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    {/*<span className="padding">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                      />
                        </span>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="circle-wrapper-mobile" className="d-block d-lg-none">
            <div className="circle1 transparent_class">
              <div className="wrapper"></div>
            </div>
            <div className="circle2 transparent_class">
              <div className="wrapper">
                <p className="notable">{t("communities.notable")}</p>
              </div>
            </div>
            <div className="row cover">
              <div className="ookbee">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/icon-ookbee.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="head">DIGITAL PUBLICATION</p>
                      <p className="subHead">OOKBEE</p>
                      <p className="subDescription">
                        {t("communities.ookbeeCommunity")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row imgScreen"
                    dangerouslySetInnerHTML={{
                      __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="100%"
                      height="100%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/279x569 ookbeer2.mp4"
                    />,
                  `,
                    }}
                  ></div>
                  {/*<div className="row imgScreen">
                    <video
                      width="100%"
                      height="100%"
                      preload="auto"
                      loop
                      autoPlay
                      muted
                      controls
                      style={{borderRadius:"3em"}}
                    >
                      <source
                        src={
                          "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/279x569 ookbeer2.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                      </div>*/}
                  <div className="row description">
                    <div className="col-auto">
                      <p>{t("communities.ookbeeDescription")}</p>
                    </div>
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/view.png"
                      />
                      <p className="titleIcon reIcon">100 millions</p>
                      <p className="descriptionIcon">
                        Screen/Pageview per month
                      </p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/new_user.png"
                      />
                      <p className="titleIcon reIcon2">3M+</p>
                      <p className="descriptionIcon">MAU</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOokbee/calendar.png"
                      />
                      <p className="titleIcon reIcon3">Users Spend</p>
                      <p className="descriptionIcon">60 Mins/Day</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div>
                    <div id="left">
                      <a href="https://www.ookbee.com/" target="_blank">
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/Ookbee/"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                        />
                      </a>
                      <a href="https://twitter.com/OokbeeApp" target="_blank">
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                        />
                      </a>
                    </div>
                    <div id="right">
                      <span className="padding">
                        <a
                          href="https://apps.apple.com/th/app/ookbee-ร-านหน-งส-อออนไลน/id667829467?l=th"
                          target="_blank"
                        >
                          <img
                            className="imgIcon"
                            alt="icon"
                            src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                          />
                        </a>
                      </span>
                      <span className="padding">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.ookbee.ookbeeapp&hl=th"
                          target="_blank"
                        >
                          <img
                            className="imgIcon"
                            alt="icon"
                            src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                          />
                        </a>
                      </span>
                      {/*<span className="padding">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                      />
                    </span>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="joy">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/icon.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="head">Thailand’s First</p>
                      <p className="subHead">Chat Style Novel Platform</p>
                      <p className="subDescription">
                        {t("communities.joyCommunity")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row imgScreen"
                    dangerouslySetInnerHTML={{
                      __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="100%"
                      height="100%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/Joylada 530x1080.mp4"
                    />,
                  `,
                    }}
                  ></div>
                  {/*<div className="row imgScreen">
                    <video
                      width="100%"
                      height="100%"
                      preload="auto"
                      style={{borderRadius:"3em"}}
                      loop
                      autoPlay
                      muted
                      controls
                    >
                      <source
                        src={
                          "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/Joylada 530x1080.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                      </div>*/}
                  <div className="row description">
                    <div className="col-auto">
                      <p>{t("communities.joyDescription")}</p>
                    </div>
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/touch.png"
                      />
                      <p className="titleIcon reIcon">2 Billions Joys</p>
                      <p className="descriptionIcon">Screen Touch per Day</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/new_user.png"
                      />
                      <p className="titleIcon reIcon2">3M+</p>
                      <p className="descriptionIcon">MAU</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsJoy/calendar.png"
                      />
                      <p className="titleIcon reIcon3">Users Spend</p>
                      <p className="descriptionIcon">90 Mins/Day</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://www.joylada.com/home" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/webjoylada/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>
                    <a href="https://twitter.com/webjoylada" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/us/app/id1250405456?ls=1"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ookbee.joyapp.android"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://appgallery.huawei.com/#/app/C101105549?accessId=fadb0b708bce428fbfdc15f7c9f81d81"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="tunwalai">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/icon v.4.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="head">Leading social platform for</p>
                      <p className="subHead">UCG Novels</p>
                      <p className="subDescription">
                        {t("communities.tunwalaiCommunity")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row imgScreen"
                    dangerouslySetInnerHTML={{
                      __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="100%"
                      height="100%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/279x569 ธัญวลัยr2.mp4"
                    />,
                  `,
                    }}
                  ></div>
                  {/*<div className="row imgScreen">
                    <video
                      width="100%"
                      height="100%"
                      preload="auto"
                      loop
                      autoPlay
                      muted
                      controls
                      style={{borderRadius:"3em"}}
                    >
                      <source
                        src={
                          "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/279x569 ธัญวลัยr2.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                      </div>*/}

                  <div className="row description">
                    <div className="col-auto">
                      <p>{t("communities.tunwalaiDescription")}</p>
                    </div>
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/view.png"
                      />
                      <p className="titleIcon reIcon">300 millions</p>
                      <p className="descriptionIcon">
                        Screen/Pageview per month
                      </p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/new_user.png"
                      />
                      <p className="titleIcon reIcon2">3M+</p>
                      <p className="descriptionIcon">MAU</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsTunwalai/calendar.png"
                      />
                      <p className="titleIcon reIcon3">Users Spend</p>
                      <p className="descriptionIcon">60 Mins/Day</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="http://www.tunwalai.com/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>

                    <a
                      href="https://www.facebook.com/webtunwalai/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>

                    <a href="https://twitter.com/webtunwalai" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/th/app/ธ-ญวล-ย-น-ยายออนไลน/id1087664350"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ookbee.tunwalai.android"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://appgallery.huawei.com/#/app/C100989167?accessId=e1e851eba8374440bd0504d512d2ec52"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="adung">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/icon.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="head">Horoscope social</p>
                      <p className="subHead">
                        Online Horoscope Social Platform
                      </p>
                      <p className="subDescription">
                        {t("communities.aDungCommunity")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row imgScreen"
                    dangerouslySetInnerHTML={{
                      __html: `
                    <video
                      loop
                      muted
                      autoplay
                      width="100%"
                      height="100%"
                      controls
                      playsinline
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/279x569 aดวงr2.mp4"
                    />,
                  `,
                    }}
                  ></div>
                  {/*<div className="row imgScreen">
                    <video
                      width="100%"
                      height="100%"
                      preload="auto"
                      loop
                      autoPlay
                      muted
                      controls
                      style={{borderRadius:"3em"}}
                    >
                      <source
                        src={
                          "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/279x569 aดวงr2.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                      </div>*/}
                  <div className="row description">
                    <div className="col-auto">
                      <p>{t("communities.aDungDescription")}</p>
                    </div>
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/view.png"
                      />
                      <p className="titleIcon reIcon">800,000+</p>
                      <p className="descriptionIcon">
                        Followers on Social Media
                      </p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/new_user.png"
                      />
                      <p className="titleIcon reIcon2">2M+</p>
                      <p className="descriptionIcon">
                        MAU (Application and Website combined)
                      </p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsAdung/calendar.png"
                      />
                      <p className="titleIcon reIcon3">3,700</p>
                      <p className="descriptionIcon">Fortune tellers</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://aduang.co/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>

                    <a
                      href="https://www.facebook.com/aduang.co/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>

                    <a href="https://twitter.com/aduangTH" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/app/apple-store/id1227408979?pt=1560061"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ookbee.aduang"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    {/*<span className="padding">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                      />
                    </span>*/}
                  </div>
                </div>
              </div>
              <div className="beeber">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/icon.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="head">Beeber</p>
                      <p className="subHead">
                        Beeber - Video and Audio LiveStreaming
                      </p>
                      <p className="subDescription">
                        {t("communities.beeberCommunity")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row imgScreen"
                    dangerouslySetInnerHTML={{
                      __html: `
                  <video
                    loop
                    muted
                    autoplay
                    width="100%"
                    height="100%"
                    controls
                    playsinline
                    src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/Beeber 530x1080.mp4"
                  />,
                `,
                    }}
                  ></div>
                  {/*<div className="row imgScreen">
                    <video
                      width="100%"
                      height="100%"
                      preload="auto"
                      loop
                      autoPlay
                      muted
                      controls
                      style={{borderRadius:"3em"}}
                    >
                      <source
                        src={
                          "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/Beeber 530x1080.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                      </div>*/}

                  <div className="row description">
                    <div className="col-auto">
                      <p>{t("communities.beeberDescription")}</p>
                    </div>
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/download.png"
                      />
                      <p className="titleIcon reIcon">500K+</p>
                      <p className="descriptionIcon">App Downloads</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/dj.png"
                      />
                      <p className="titleIcon reIcon2">20K+</p>
                      <p className="descriptionIcon">DJs</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsBeeber/microphone.png"
                      />
                      <p className="titleIcon reIcon3"> 350k+</p>
                      <p className="descriptionIcon">Live Rooms</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://beeber.live/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/BeeBer.live/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>
                    <a href="https://twitter.com/beeberlive" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/th/app/fungjai/id999518766"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.fungjai"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    {/*<span className="padding">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                      />
                  </span>*/}
                  </div>
                </div>
              </div>
              <div className="fungjai">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/icon.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="head">Fungjai – Leading Music Streaming</p>
                      <p className="subHead">& Live Event Ecosytem</p>
                      <p className="subDescription">
                        {t("communities.fungjaiCommunity")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row imgScreen"
                    dangerouslySetInnerHTML={{
                      __html: `
                  <video
                    loop
                    muted
                    autoplay
                    width="100%"
                    height="100%"
                    controls
                    playsinline
                    src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/Fungjai 530x1080.mp4"
                  />,
                `,
                    }}
                  ></div>
                  {/*<div className="row imgScreen">
                    <video
                      width="100%"
                      height="100%"
                      preload="auto"
                      loop
                      autoPlay
                      muted
                      controls
                      style={{borderRadius:"3em"}}
                    >
                      <source
                        src={
                          "https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/Fungjai 530x1080.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                      </div>*/}

                  <div className="row description">
                    <div className="col-auto">
                      <p>{t("communities.fungjaiDescription")} </p>
                    </div>
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/follower.png"
                      />
                      <p className="titleIcon reIcon">420,000+</p>
                      <p className="descriptionIcon">Followers on Facebook</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/new_song.png"
                      />
                      <p className="titleIcon reIcon2">2M</p>
                      <p className="descriptionIcon">Song streamed per month</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsFungjai/new_user.png"
                      />
                      <p className="titleIcon reIcon3">4.6K</p>
                      <p className="descriptionIcon">Artists</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="left">
                    <a href="https://www.fungjai.com/home" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/world.png"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/hellofungjai/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/facebook.png"
                      />
                    </a>
                    <a href="https://twitter.com/hellofungjai" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/twitter.png"
                      />
                    </a>
                  </div>

                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://apps.apple.com/th/app/fungjai/id999518766"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadApple.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.fungjai"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadPlayStore.png"
                        />
                      </a>
                    </span>
                    {/*<span className="padding">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/dowloadHuawai.png"
                      />
                </span>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="platformsLifestyle">
          <div id="circle-wrapper-lifestyle" className="d-none d-lg-block">
            <div className="circle1 transparent_class">
              <div className="wrapper"></div>
            </div>
            <div className="circle2 transparent_class">
              <div className="wrapper">
                <p className="notable">{t("communities.notableLifestyle")}</p>
              </div>
            </div>
            <div className="row">
              <div className="cchanel">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/icon v.2.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="subHead">
                          {t("communities.cChannelCommunityTitle")}
                        </p>
                        <p className="subDescription">
                          {" "}
                          {t("communities.cChannelCommunitySubTitle")}
                        </p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/view.png"
                        />
                        <p className="titleIcon reIcon">15 millions</p>
                        <p className="descriptionIcon">Video view per month</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/new_user.png"
                        />
                        <p className="titleIcon reIcon2">9M+</p>
                        <p className="descriptionIcon">MAU</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/user.png"
                        />
                        <p className="titleIcon reIcon3">2.6 millions+</p>
                        <p className="descriptionIcon">Followers on Facebook</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div className="row screen">
                      <img
                        className="imgScreen"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/screen.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://www.facebook.com/cchannel.thailand/"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://www.instagram.com/cchannel_th/"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/instagram.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://www.youtube.com/channel/UC_C3MvkeoqbwxyXbWLGfYlg"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/youtube.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://www.tiktok.com/@cchannel_th"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/tiktok.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://www.sanook.com/women/tag/c+channel/"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/s.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="officeHit">
              <div className="row containers">
                <div className="col-8 left">
                  <div className="row digital">
                    <div className="col-3">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/icon.png"
                      />
                    </div>
                    <div className="col-9 text">
                      <p className="subHead">
                        {t("communities.officeHitCommunityTitle")}
                      </p>
                      <p className="subDescription">
                        {" "}
                        {t("communities.officeHitCommunitySubTitle")}
                      </p>
                    </div>
                  </div>
                  <div className="row imageDescription">
                    <div className="col-3">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/user.png"
                      />
                      <p className="titleIcon reIcon">413K+</p>
                      <p className="descriptionIcon">Total users</p>
                      <p className="descriptionIcon">(combined platforms)</p>
                    </div>
                    <div className="col-3">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/promotion.png"
                      />
                      <p className="titleIcon reIcon2">902K+</p>
                      <p className="descriptionIcon">Post</p>
                      <p className="descriptionIcon">reach</p>
                    </div>
                    <div className="col-3">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/social-media.png"
                      />
                      <p className="titleIcon reIcon3">121K+</p>
                      <p className="descriptionIcon">Post</p>
                      <p className="descriptionIcon">engagements</p>
                    </div>
                    <div className="col-3">
                      <img
                        className="imgIconRight2"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/path.png"
                      />
                      <p className="titleIcon reIcon3">70%</p>
                      <p className="descriptionIcon">Education</p>
                      <p className="descriptionIcon">University</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 right">
                  <div className="row screen">
                    <img
                      className="imgScreen"
                      alt="icon"
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/screen.png"
                    />
                  </div>
                </div>
              </div>
              <div className="footers">
                <div id="right">
                  <span className="padding">
                    <a
                      href="https://www.facebook.com/OfficeHitArai"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                      />
                    </a>
                  </span>
                  <span className="padding">
                    <a
                      href="https://www.instagram.com/officehitarai/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/instagram.png"
                      />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
            {/*<div className="row">
              <div className="kidmook">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/icon v.2.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="subHead">
                          {t("communities.kidmookCommunityTitle")}
                        </p>
                        <p className="subDescription">
                          {" "}
                          {t("communities.kidmookCommunitySubTitle")}
                        </p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/follower.png"
                        />
                        <p className="titleIcon reIcon">1,000,000+</p>
                        <p className="descriptionIcon">Followers on Facebook</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/new_user.png"
                        />
                        <p className="titleIcon reIcon2">2M</p>
                        <p className="descriptionIcon">
                          Active users on Facebook page
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/bangkok.png"
                        />
                        <p className="titleIcon reIcon3">80%</p>
                        <p className="descriptionIcon">
                          of followers are not Bangkok-based
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div className="row screen">
                      <img
                        className="imgScreen"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/screen.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://www.facebook.com/kidmook/"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://www.instagram.com/kidmook/"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/instagram.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="officeBreak">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/icon v.2.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="subHead">
                          {t("communities.officeBreakCommunityTitle")}
                        </p>
                        <p className="subDescription">
                          {" "}
                          {t("communities.officeBreakCommunitySubTitle")}
                        </p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/follower.png"
                        />
                        <p className="titleIcon reIcon">495,000+</p>
                        <p className="descriptionIcon">Followers on Facebook</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/new_user.png"
                        />
                        <p className="titleIcon reIcon2">1M</p>
                        <p className="descriptionIcon">
                          Active users on Facebook page
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/education.png"
                        />
                        <p className="titleIcon reIcon3">70%</p>
                        <p className="descriptionIcon">Education University</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div className="row screen">
                      <img
                        className="imgScreen"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/screen.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://www.facebook.com/OfficeBreakTH"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://www.youtube.com/channel/UC8lX2WZEXRbMbAbige5eltw/featured"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/youtube.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="superSalaryMan">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/icon v.2.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="subHead">
                          {" "}
                          {t("communities.superSalaryManCommunityTitle")}
                        </p>
                        <p className="subDescription">
                          {" "}
                          {t("communities.superSalaryManCommunitySubTitle")}
                        </p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/followers.png"
                        />
                        <p className="titleIcon reIcon">294,000+</p>
                        <p className="descriptionIcon">followers on Facebook</p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/new_user.png"
                        />
                        <p className="titleIcon reIcon2">1.5M</p>
                        <p className="descriptionIcon">
                          Active users on Facebook page
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/area.png"
                        />
                        <p className="titleIcon reIcon3">60%</p>
                        <p className="descriptionIcon">
                          of followers are Urban target
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div className="row screen">
                      <img
                        className="imgScreen"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/screen.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://www.facebook.com/SeoulWhatOfficial"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://www.tiktok.com/@seoulwhat_official"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/tiktok.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>*/}
            <div className="row">
              <div className="seoulWhat">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSeoulWhat/icon v.2.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="subHead">
                          {t("communities.seoulWhatManCommunityTitle")}
                        </p>
                        <p className="subDescription">
                          {" "}
                          {t("communities.seoulWhatManCommunitySubTitle")}
                        </p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-auto center">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSeoulWhat/followers.png"
                        />
                        <p className="titleIcon reIcon">193,000+</p>
                        <p className="descriptionIcon">Followers on Facebook</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div className="row screen">
                      <img
                        className="imgScreen"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSeoulWhat/screen.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://www.facebook.com/SeoulWhatOfficial"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="row">
              <div className="thefilp">
                <div className="row containers">
                  <div className="col-8 left">
                    <div className="row digital">
                      <div className="col-3">
                        <img
                          className="imgLogo"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/icon v.2.png"
                        />
                      </div>
                      <div className="col-9 text">
                        <p className="subHead">
                          The <span className="subHead2">Flip</span>
                        </p>
                        <p className="subDescription">
                          {" "}
                          {t("communities.flipCommunitySubTitle")}
                        </p>
                      </div>
                    </div>
                    <div className="row imageDescription">
                      <div className="col-4">
                        <img
                          className="imgIconLeft"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/followers.png"
                        />
                        <p className="titleIcon reIcon">3X</p>
                        <p className="descriptionIcon">
                          Reach beyond followers
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconCenter"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/photo.png"
                        />
                        <p className="titleIcon2 reIcon2">15%</p>
                        <p className="descriptionIcon">
                          Average engagement rate Per Photo post
                        </p>
                      </div>
                      <div className="col-4">
                        <img
                          className="imgIconRight"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/video.png"
                        />
                        <p className="titleIcon reIcon3">10K+</p>
                        <p className="descriptionIcon">Rich context.</p>
                        <p className="descriptionIcon">High engagement</p>
                        <p className="descriptionIcon">video content</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 right">
                    <div className="row screen">
                      <img
                        className="imgScreen"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/screen.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="right">
                    <span className="padding">
                      <a
                        href="https://www.facebook.com/flipissue/"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                        />
                      </a>
                    </span>
                    <span className="padding">
                      <a
                        href="https://www.youtube.com/channel/UCwW_UqSJnNmjcU7qgS7BLQw"
                        target="_blank"
                      >
                        <img
                          className="imgIcon"
                          alt="icon"
                          src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/youtube.png"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
          <div id="circle-wrapper-lifestyle-mobile" className="d-block d-lg-none">
            <div className="circle1 transparent_class">
              <div className="wrapper"></div>
            </div>
            <div className="circle2 transparent_class">
              <div className="wrapper">
                <p className="notable">{t("communities.notableLifestyle")}</p>
              </div>
            </div>
            <div className="row cover">
              <div className="cchanel">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/icon v.2.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="subHead">
                        {t("communities.cChannelCommunityTitle")}
                      </p>
                      <p className="subDescription">
                        {t("communities.cChannelCommunitySubTitle")}
                      </p>
                    </div>
                  </div>
                  <div className="row center">
                    {/*<div className="col-3">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/easy.png"
                      />
                    </div>*/}
                    <div className="col-12">
                      <img
                        className="imgScreen"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/screen.png"
                      />
                    </div>
                    {/*<div className="col-3">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/alwaysMb.png"
                      />
                  </div>*/}
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/view.png"
                      />
                      <p className="titleIcon reIcon">15 millions</p>
                      <p className="descriptionIcon">Video view per month</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/new_user.png"
                      />
                      <p className="titleIcon reIcon2">9M+</p>
                      <p className="descriptionIcon">MAU</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsCchannel/user.png"
                      />
                      <p className="titleIcon reIcon3">2.6 millions+</p>
                      <p className="descriptionIcon">Followers on Facebook</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="center">
                    <a
                      href="https://www.facebook.com/cchannel.thailand/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                      />
                    </a>

                    <a
                      href="https://www.instagram.com/cchannel_th/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/instagram.png"
                      />
                    </a>

                    <a
                      href="https://www.youtube.com/channel/UC_C3MvkeoqbwxyXbWLGfYlg"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/youtube.png"
                      />
                    </a>

                    <a
                      href="https://www.tiktok.com/@cchannel_th"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/tiktok.png"
                      />
                    </a>

                    <a
                      href="https://www.sanook.com/women/tag/c+channel/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/s.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="officeHit">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/icon.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="subHead">
                        {t("communities.officeHitCommunityTitle")}
                      </p>
                      <p className="subDescription">
                        {t("communities.officeHitCommunitySubTitle")}
                      </p>
                    </div>
                  </div>
                  <div className="row center">
                    <div className="col-12">
                      <img
                        className="imgScreen"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/screen.png"
                      />
                    </div>
                  </div>
                  <div className="row imageDescription">
                    <div className="col-3">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/user.png"
                      />
                      <p className="titleIcon reIcon">413K+</p>
                      <p className="descriptionIcon">Total users</p>
                      <p className="descriptionIcon">(combined platforms)</p>
                    </div>
                    <div className="col-3">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/promotion.png"
                      />
                      <p className="titleIcon reIcon2">902K+</p>
                      <p className="descriptionIcon">Post</p>
                      <p className="descriptionIcon">reach</p>
                    </div>
                    <div className="col-3">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/social-media.png"
                      />
                      <p className="titleIcon reIcon3">121K+</p>
                      <p className="descriptionIcon">Post</p>
                      <p className="descriptionIcon">engagements</p>
                    </div>
                    <div className="col-3">
                    <img
                      className="imgIconRight2"
                      alt="icon"
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeHit/path.png"
                    />
                    <p className="titleIcon reIcon3">70%</p>
                    <p className="descriptionIcon">Education</p>
                    <p className="descriptionIcon">University</p>
                  </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="center">
                    <a
                      href="https://www.facebook.com/OfficeHitArai"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                      />
                    </a>

                    <a
                      href="https://www.instagram.com/officehitarai/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/instagram.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/*<div className="kidmook">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/icon v.2.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="subHead">
                        {" "}
                        {t("communities.kidmookCommunityTitle")}
                      </p>
                      <p className="subDescription">
                        {t("communities.kidmookCommunitySubTitle")}
                      </p>
                    </div>
                  </div>
                  <div className="row center">
                    <img
                      className="imgScreen"
                      alt="icon"
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/screen.png"
                    />
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/follower.png"
                      />
                      <p className="titleIcon reIcon">1,000,000+</p>
                      <p className="descriptionIcon">Followers on Facebook</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/new_user.png"
                      />
                      <p className="titleIcon reIcon2">2M</p>
                      <p className="descriptionIcon">
                        Active users on Facebook page
                      </p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformKidmook/bangkok.png"
                      />
                      <p className="titleIcon reIcon3">80%</p>
                      <p className="descriptionIcon">
                        of followers are not Bangkok-based
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="center">
                    <a href="https://www.facebook.com/kidmook/" target="_blank">
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/kidmook/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/instagram.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="officeBreak">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/icon v.2.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="subHead">
                        {t("communities.officeBreakCommunityTitle")}
                      </p>
                      <p className="subDescription">
                        {t("communities.officeBreakCommunitySubTitle")}
                      </p>
                    </div>
                  </div>
                  <div className="row center">
                    <img
                      className="imgScreen"
                      alt="icon"
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/screen.png"
                    />
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/follower.png"
                      />
                      <p className="titleIcon reIcon">495,000+</p>
                      <p className="descriptionIcon">Followers on Facebook</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/new_user.png"
                      />
                      <p className="titleIcon reIcon2">1M</p>
                      <p className="descriptionIcon">
                        Active users on Facebook page
                      </p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsOfficeBreak/education.png"
                      />
                      <p className="titleIcon reIcon3">70%</p>
                      <p className="descriptionIcon">Education University</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="center">
                    <a
                      href="https://www.facebook.com/OfficeBreakTH"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC8lX2WZEXRbMbAbige5eltw/featured"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/youtube.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="superSalaryMan">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/icon v.2.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="subHead">
                        {t("communities.superSalaryManCommunityTitle")}
                      </p>
                      <p className="subDescription">
                        {t("communities.superSalaryManCommunitySubTitle")}
                      </p>
                    </div>
                  </div>
                  <div className="row center">
                    <img
                      className="imgScreen"
                      alt="icon"
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/screen.png"
                    />
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/followers.png"
                      />
                      <p className="titleIcon reIcon">294,000+</p>
                      <p className="descriptionIcon">Followers on Facebook</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/new_user.png"
                      />
                      <p className="titleIcon reIcon2">1.5M</p>
                      <p className="descriptionIcon">
                        Active users on Facebook page
                      </p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSuperSalaryMan/area.png"
                      />
                      <p className="titleIcon reIcon3">60%</p>
                      <p className="descriptionIcon">
                        of followers are Urban target
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="center">
                    <a
                      href="https://www.facebook.com/Thesupersalaryman"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                      />
                    </a>
                    <a
                      href="https://www.tiktok.com/@supersalaryman"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/tiktok.png"
                      />
                    </a>
                  </div>
                </div>
              </div>*/}
              <div className="seoulWhat">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSeoulWhat/icon v.2.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="subHead">
                        {t("communities.seoulWhatManCommunityTitle")}
                      </p>
                      <p className="subDescription">
                        {t("communities.seoulWhatManCommunitySubTitle")}
                      </p>
                    </div>
                  </div>
                  <div className="row center">
                    <img
                      className="imgScreen"
                      alt="icon"
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSeoulWhat/screen.png"
                    />
                  </div>
                  <div className="row imageDescription">
                    <div className="col-auto center">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsSeoulWhat/followers.png"
                      />
                      <p className="titleIcon reIcon">193,000+</p>
                      <p className="descriptionIcon">Followers on Facebook</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="center">
                    <a
                      href="https://www.facebook.com/SeoulWhatOfficial"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/*<div className="thefilp">
                <div className="containers">
                  <div className="row digital">
                    <div className="col-4">
                      <img
                        className="imgLogo"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/icon v.2.png"
                      />
                    </div>
                    <div className="col-8 text">
                      <p className="subHead">
                        The <span className="subHead2">Flip</span>
                      </p>
                      <p className="subDescription">
                        {t("communities.flipCommunitySubTitle")}
                      </p>
                    </div>
                  </div>
                  <div className="row center">
                    <img
                      className="imgScreen"
                      alt="icon"
                      src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/screen.png"
                    />
                  </div>
                  <div className="row imageDescription">
                    <div className="col-4">
                      <img
                        className="imgIconLeft"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/followers.png"
                      />
                      <p className="titleIcon reIcon">3X</p>
                      <p className="descriptionIcon">Reach beyond followers</p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconCenter"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/photo.png"
                      />
                      <p className="titleIcon2 reIcon2">15%</p>
                      <p className="descriptionIcon">
                        Average engagement rate Per Photo post
                      </p>
                    </div>
                    <div className="col-4">
                      <img
                        className="imgIconRight"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/platformsThefilp/video.png"
                      />
                      <p className="titleIcon reIcon3">10K+</p>
                      <p className="descriptionIcon">Rich context.</p>
                      <p className="descriptionIcon">High engagement</p>
                      <p className="descriptionIcon">video content</p>
                    </div>
                  </div>
                </div>
                <div className="footers">
                  <div id="center">
                    <a
                      href="https://www.facebook.com/flipissue/"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/facebook.png"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCwW_UqSJnNmjcU7qgS7BLQw"
                      target="_blank"
                    >
                      <img
                        className="imgIcon"
                        alt="icon"
                        src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/footer/youtube.png"
                      />
                    </a>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withTrans(CommunitiesPage)
